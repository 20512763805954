@tailwind base;
@tailwind components;
@tailwind utilities;

/* adamina-regular - latin */
@font-face {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/adamina-v21-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/adamina-v21-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/adamina-v21-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/adamina-v21-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/adamina-v21-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/adamina-v21-latin-regular.svg#Adamina") format("svg"); /* Legacy iOS */
}

/* manrope-300 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/manrope-v13-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/manrope-v13-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/manrope-v13-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/manrope-v13-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/manrope-v13-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/manrope-v13-latin-300.svg#Manrope") format("svg"); /* Legacy iOS */
}
/* manrope-600 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/manrope-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/manrope-v13-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/manrope-v13-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/manrope-v13-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/manrope-v13-latin-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/manrope-v13-latin-600.svg#Manrope") format("svg"); /* Legacy iOS */
}
/* manrope-700 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/manrope-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/manrope-v13-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/manrope-v13-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/manrope-v13-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/manrope-v13-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/manrope-v13-latin-700.svg#Manrope") format("svg"); /* Legacy iOS */
}

@layer components {
  html,
  body,
  #__next {
    @apply h-full w-full text-black;
  }
}

@layer base {
  .transition-1 {
    @apply transition-all duration-200 ease-in-out;
  }
}

/* todo - REFACTOR: These styles should not be used from here, but from the appropriate utility classes */
.gradient-1 {
  background: linear-gradient(to bottom right, #9a455a, #0b1423 20%, #265e79 150%);
}
.gradient-2 {
  background: linear-gradient(135deg, #9a455a -10%, #0b1423 30%, #265e79 100%);
}
.gradient-light {
  background: linear-gradient(
    270.73deg,
    rgba(243, 243, 243, 0.3) 0.63%,
    rgba(232, 228, 227, 0.3) 99.42%
  );
}

/* https://stackoverflow.com/questions/51496204/border-gradient-with-border-radius */
.border-gradient-base {
  position: relative;
  z-index: 0;
}
.border-gradient-base:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.MuiButton-containedPrimary:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}

.react-datepicker-wrapper {
  display: flex !important;
}
/* Will be fixed when the globals and tailwind.config file from the component library will be merged into the hiltermann-lease-site project */
.p-10 {
  padding: 40px;
}

.w-4 {
  width: 1rem;
}

.max-h-0 {
  max-height: 0;
}
.max-h-96 {
  max-height: 24rem;
}

/* In some cases "break-words" does not work */
.word-break-word {
  word-break: break-word;
}
